<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid">
          <ul
            class="breadcrumb ProximaNovaRegular"
            style="margin-left: 52px; margin-top: 12px"
          >
            <li>
              <span>Upload Data</span>
            </li>
            <li>
              <span>Agile</span>
            </li>
            <li class="ProximaNovaBold">KPI</li>
          </ul>
        </div>
                
        <div class="w-100 py-4">
          <div
            class="container-fluid"
            style="margin-top: 36px; padding-left: 52px;"
          >
            <div class="col-md-12 col-lg-12">
              <div class="card w-100 kpi-section">
                <div class="row">
                  <div class="col-md-4 colo-lg-4">
                    <div class="w-100 p-5 m-5">
                      <img
                        src="@/assets/Icons/group-21.png"
                        alt="KPI"
                        class="img img-responsive"
                      />
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7">
                    <div class="w-100 row " style="height: 100%">
                      <div
                        class="w-100 d-flex px-5"
                        style="height: 35%; justify-content: start; align-items: flex-end;"
                      >
                        <span
                          class="ProximaNovaBold"
                          style="font-size: x-large;"
                          >Define KPI</span
                        >
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="kpidropdown"
                            :placeHolder="'Select'"
                            :labelText="'KPI'"
                            class="mr-3"
                            v-bind:value="'Sales'"
                            @input="selectedKPi($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="marketDropdown"
                            :labelText="'Market Type'"
                            class="mr-5"
                            @input="selectMarket($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div class="w-100 pr-5" style="height: 25%">
                        <div
                          class="row mr-2 pr-2"
                          style="float: right;"
                          :class="{ changeColor: isDisable }"
                        >
                          <w-button
                            :buttonLoader="'normal'"
                            :buttonText="'Create Plan'"
                            :class="{ resetPasswordLink: isDisable }"
                            :isDisable="isDisable"
                            @buttonClicked="createPlan"
                          >
                          </w-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <solution-modal ref="solutionModal" @progressDone="doneProgress" />
      </main>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import SolutionModal from "@/components/Modal/SolutionModal.vue";
export default {
  props: [],
  components: {
    "w-button": Button,
    "w-dropdown": Dropdown,
    "solution-modal": SolutionModal,
  },
  data() {
    return {
      kpidropdown: [
        { text: "Leads", id: "leads" },
        { text: "Sales", id: "sales"},
        { text: "Profit Margin", id: "Profit Margin" },
        { text: "Volume", id: "volume" },
        { text: "Cart Value", id: "Cart Value" },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "USA", id: "USA" },
        { text: "HSM", id: "hsm" },
        { text: "KER", id: "ker" },
        { text: "KA", id: "KA" },
      ],
      selectedMarket: "",
      selectedKPI: "",
    };
  },
  computed: {
    isDisable() {
      if (
        this.selectedMarket !== "Select" &&
        this.selectedKPI !== "Select" &&
        this.selectedMarket !== "" &&
        this.selectedKPI !== ""
      ) {
        return false;
      }
      return true;
    },
    disableConnectors() {
      if (this.activeFrom === 1) {
        if (this.selectedConnectors.length > 0) {
          return false;
        }
      } else if (this.activeFrom === 2) {
        return false;
      }
      return true;
    },
  },
  methods: {
    doneProgress(){
      this.$router.push("/upload-data/model-result");
    },
    createPlan() {
      this.$refs.solutionModal.open();
    },
    selectedKPi(e) {
      if (this.selectedKPI != e) {
        this.selectedKPI = e;
        console.log(e);
      }
    },
    selectMarket(e) {
      if (e === this.selectedMarket || e === "Select") {
        return;
      }
      sessionStorage.setItem("kpiMarket", JSON.stringify(e));
      this.selectedMarket = e;
    },   
  },
  created() {},
};
</script>
<style scoped>
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
@media screen and (max-width: 640px){
  .w-100.p-5.m-5 {
        margin: 0 !important;
        padding: 15px !important;
    }
    .kpi-section {
        height: 725px;
    }
    /deep/ div#__BVID__67 {
        padding-left: 0 !important;
    }
    /deep/.container-fluid {
      padding-left: 0 !important;
  }
}
@media screen and (max-width: 768px){
    .w-100.p-5.m-5 {
        margin: 0 !important;
        padding: 15px !important;
    } 
    img.img.img-responsive {
        margin: auto;
    }  
    .kpi-section {
        height: 725px;
    }
}
</style>
